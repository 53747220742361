import { defineStore, storeToRefs } from 'pinia';
import { toast } from '@/shared/native/toast';
import { createReactionOnWorld, deleteReactionOnWorld, updateReactionOnWorld } from '@/shared/actions/reactions';
import { UserReaction, ReactionWorldCounts, WorldsInfo } from '@/shared/types/static-types';

import { getStoryWorldDraft, getWorldsFromSlug } from '@/shared/actions/worlds';
import { imageStore } from '@/shared/pinia-store/images';

const isUserReactedSameReaction = (reaction: string, world: WorldsInfo, isInstant = false): boolean => {
  const { user_reaction } = world;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReaction = (reaction: string, world: WorldsInfo): boolean => {
  const { user_reaction } = world;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackend = async (reaction: string, world: WorldsInfo, isInstant: boolean = false) => {
  const target = 'world';
  const { user_reaction, id: worldId = '' } = world;

  // delete
  if (isUserReactedSameReaction(reaction, world, isInstant)) {
    return await deleteReactionOnWorld({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, world)) {
    return await updateReactionOnWorld({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnWorld({ objectId: worldId, reaction, target });
  return data;
};

const updateReactionsDataForWorld = (
  reaction: string,
  world: WorldsInfo,
  newReaction: UserReaction
): Partial<WorldsInfo> => {
  const worldData = cloneDeep(world);
  worldData.reaction_counts = worldData.reaction_counts || { total_count: 0 };
  const totalCount = worldData.reaction_counts.total_count;
  let userReaction = worldData.user_reaction || ({} as UserReaction);
  const prevUserReaction = worldData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (worldData.user_reaction as UserReaction) {
    worldData.reaction_counts[prevUserReaction.reaction as keyof ReactionWorldCounts]--;
    if (newReaction.reaction in worldData.reaction_counts) {
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    worldData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]) {
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    } else {
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, worldData)) {
    worldData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, worldData) && !newReaction) {
    worldData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    worldData.user_reaction = undefined;
  } else {
    worldData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: worldData.user_reaction,
    reaction_counts: worldData.reaction_counts,
  };
  return out;
};

export const newTextKey = (storyWorld: WorldsInfo) => {
  const info = storyWorld;
  const keys = Object.keys(info);
  const maxKeyNumber =
    keys
      .filter((key) => key.includes('text'))
      .map((key) => Number(key.replaceAll('text', '')))
      .sort((a, b) => a - b)
      .pop() || 0;
  const newKey = `text${maxKeyNumber + 1}`;
  return newKey;
};

export interface IStoryWorldStore {
  storyWorld: WorldsInfo | null;
  isLoading: boolean;
  isSectionsCollapsed: SectionsCollapsed;
}

export enum SectionsCollapsed {
  true = 1,
  false = 0,
  noCare = -1,
}

const useStoryWorld = defineStore('story-world', {
  state: (): IStoryWorldStore => ({
    storyWorld: null,
    isLoading: false,
    isSectionsCollapsed: 0,
  }),
  actions: {
    async loadStoryWorldBySlug(slug: string) {
      const { reset: resetImages, setImageCollections } = imageStore();
      resetImages();
      try {
        this.storyWorld = null;
        this.isLoading = true;
        const world = await getWorldsFromSlug(slug);
        this.storyWorld = world;
        if (world.visual_collections_page?.results) {
          setImageCollections(world.visual_collections_page.results);
        }
        return world;
      } catch (error) {
        toast.show('Error loading story world', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },

    collapseSections(value: SectionsCollapsed) {
      this.isSectionsCollapsed = value;
    },
    async loadDraftWorldById(id: string) {
      const { reset: resetImages, setImageCollections } = imageStore();
      resetImages();
      try {
        this.isLoading = true;
        const world = await getStoryWorldDraft(id);
        this.storyWorld = world;
        if (world.visual_collections_page?.results) {
          setImageCollections(world.visual_collections_page.results);
        }
      } catch (error) {
        toast.show('Error loading world', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    async react(reaction: string, isInstant = false) {
      if (!this.storyWorld) return;
      const newReaction = await sendReactionToBackend(reaction, this.storyWorld, isInstant);
      const updatedReactionsData = updateReactionsDataForWorld(reaction, this.storyWorld, newReaction);
      this.storyWorld = {
        ...this.storyWorld,
        ...updatedReactionsData,
      };
      return { newReaction, updatedReactionsData };
    },
  },
  getters: {
    world(): WorldsInfo | null {
      return this.storyWorld;
    },
    loading(): boolean {
      return this.isLoading;
    },
    sectionsCollapsed(): SectionsCollapsed {
      return this.isSectionsCollapsed;
    },
  },
});

export const storyWorldStore = () => {
  const store = useStoryWorld();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
